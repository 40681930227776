import { useState } from 'react'
import { useRouter } from 'next/router'
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Countries from './dropdownItemsCountries'


function SelectByCountry() {

    const router = useRouter()
    const [countryName, setCountryName] = useState('Country')

    return (<Container >
        <Row >
            <Col className='mt-1 mt-sm-2 mt-lg-3 mcountry' >
                <Dropdown
                    onSelect={async (e) => {
                        await setCountryName(e)
                        await router.push(`/selectByCountry/${e}`)
                    }
                    }>
                    <Dropdown.Toggle id="dropdown-basic">
                        {countryName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ overflow: 'auto', height: '40vh', zIndex: 10000 }}>
                        <Countries />
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    </Container>
    );
}
export default SelectByCountry;